<section class="subscribe-area ptb-100 jarallax" data-jarallax='{"speed": 0.3}'>
    <div class="container">
        <div class="newsletter">
            <h2>Join Our <b>Newsletter</b></h2>
            <form class="newsletter-form">
                <input type="email" class="form-control" placeholder="Your Email Address" name="EMAIL">
                <button type="submit">Subscribe</button>
            </form>
        </div>
    </div>
</section>