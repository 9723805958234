<section class="page-title-area item-bg4">
    <div class="container">
        <h2>404 Error</h2>
    </div>
</section>

<section class="error-area ptb-100">
    <div class="container">
        <div class="error-content">
            <img src="assets/img/error-404.png" alt="error">
            <form class="search-form">
                <input type="text" class="search-field" placeholder="search">
                <button type="submit" class="search-submit">Search</button>
            </form>
        </div>
    </div>
</section>