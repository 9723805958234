<section class="page-title-area item-bg3">
    <div class="container">
        <h2>Blog Details</h2>
    </div>
</section>

<section class="blog-details-area ptb-100">
    <div class="container">
        <div class="row">
            <div class="col-lg-8 col-md-12">
                <div class="blog-details">
                    <div class="post-image">
                        <img src="assets/img/blog1.jpg" alt="image">
                    </div>
                    <h3>The Most Popular New top Business Apps</h3>
                    <div class="blog-meta">
                        <ul>
                            <li><i class="icofont-ui-user"></i> <a href="#">Admin</a></li>
                            <li><i class="icofont-clock-time"></i> June 20, 2020</li>
                            <li><i class="icofont-comment"></i> <a href="#">6 Comments</a></li>
                            <li><i class="icofont-ui-folder"></i> <a href="#">Event</a></li>
                        </ul>
                    </div>
                    <p>There are many variations of passages of Lorem Ipsum available but the majority have suffered alteration in that some form by injected humour or randomised words which don’t look even slightly believable. If you are going to use a passage of Lorem Ipsum you need to be sure there isn’t anything of embarrassing. There are many that an variations of passages of Lorem Ipsum available but the majority have suffered alteration in that some form by a injected humour or randomised words which don’t look even of slightly believable. If you are going to use a the passage of Lorem Ipsum you need to be sure there isn’t anything embarrassing.</p>
                    <blockquote class="blockquote">
                        <p>There are many variations of passages of Lorem the Ipsum available but the that as that majority have is suffered alteration.</p>
                    </blockquote>
                    <p>There are many variations of passages of Lorem Ipsum available but the majority have suffered alteration in that some form by injected humour or randomised words which don’t look even slightly believable. If you are going to use a passage of Lorem Ipsum you need to be sure there isn’t anything of embarrassing.</p>
                    <p>There are many variations of passages of Lorem Ipsum available but the majority have suffered alteration in that some form by injected humour or randomised words which don’t look even slightly believable. If you are going to use a passage of Lorem Ipsum you need to be sure there isn’t anything of embarrassing.</p>
                    <p>Randomised words which don’t look even slightly believable. If you are going a to use a passage you need to be sure there isn’t anything embarrassing. Consectetur adipisicing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.</p>
                </div>
                <div class="post-tag-media">
                    <div class="row h-100 align-items-center">
                        <div class="col-lg-6 col-md-7">
                            <ul class="tag">
                                <li><span>Tags:</span></li>
                                <li><a href="#">Event,</a></li>
                                <li><a href="#">App,</a></li>
                                <li><a href="#">Software</a></li>
                            </ul>
                        </div>
                        <div class="col-lg-6 col-md-5">
                            <ul class="social-share">
                                <li><span>Share on:</span></li>
                                <li><a href="#"><i class="icofont-facebook"></i></a></li>
                                <li><a href="#"><i class="icofont-twitter"></i></a></li>
                                <li><a href="#"><i class="icofont-instagram"></i></a></li>
                                <li><a href="#"><i class="icofont-linkedin"></i></a></li>
                            </ul>
                        </div>
                    </div>
                </div>
                <div id="comments" class="comments-area">
                    <h2 class="comments-title">3 Comments</h2>
                    <ol class="comment-list">
                        <li class="comment">
                            <article class="comment-body">
                                <footer class="comment-meta">
                                    <div class="comment-author vcard">
                                        <img src="assets/img/author1.jpg" alt="image" class="avatar">
                                        <b class="fn">John Smith</b>
                                        <span class="says">says:</span>
                                    </div>
                                    <div class="comment-metadata">
                                        <a href="#">
                                            March 28, 2020 at 7:16 am
                                        </a>
                                    </div>
                                </footer>
                                <div class="comment-content">
                                    <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit. Integer posuere erat a ante. It is a long established fact that a reader will be distracted by the readable content of a page when looking at its layout.</p>
                                </div>
                                <div class="reply">
                                    <a href="#" rel="nofollow" class="comment-reply-link">Reply</a>
                                </div>
                            </article>
                            <ol class="children">
                                <li class="comment">
                                    <article class="comment-body">
                                        <footer class="comment-meta">
                                            <div class="comment-author vcard">
                                                <img src="assets/img/author2.jpg" alt="image" class="avatar">
                                                <b class="fn">Steven Warner</b>
                                                <span class="says">says:</span>
                                            </div>
                                            <div class="comment-metadata">
                                                <a href="#">
                                                    March 28, 2020 at 7:16 am
                                                </a>
                                            </div>
                                        </footer>
                                        <div class="comment-content">
                                            <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit. Integer posuere erat a ante. It is a long established fact that a reader will be distracted by the readable content of a page when looking at its layout.</p>
                                        </div>
                                        <div class="reply">
                                            <a href="#" rel="nofollow" class="comment-reply-link">Reply</a>
                                        </div>
                                    </article>
                                </li>
                            </ol>
                        </li>
                        <li class="comment">
                            <article class="comment-body">
                                <footer class="comment-meta">
                                    <div class="comment-author vcard">
                                        <img src="assets/img/author3.jpg" alt="image" class="avatar">
                                        <b class="fn">John Smith</b>
                                        <span class="says">says:</span>
                                    </div>
                                    <div class="comment-metadata">
                                        <a href="#">
                                            March 28, 2020 at 7:16 am
                                        </a>
                                    </div>
                                </footer>
                                <div class="comment-content">
                                    <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit. Integer posuere erat a ante. It is a long established fact that a reader will be distracted by the readable content of a page when looking at its layout.</p>
                                </div>
                                <div class="reply">
                                    <a href="#" rel="nofollow" class="comment-reply-link">Reply</a>
                                </div>
                            </article>
                        </li>
                    </ol>
                    <div id="respond" class="comment-respond">
                        <h3 class="comment-reply-title">Leave a Reply</h3>
                        <form id="commentform" class="comment-form">
                            <p class="comment-notes">Your email address will not be published.</p>
                            <p class="comment-form-comment">
                                <textarea id="comment" placeholder="Comment" cols="45" rows="4"></textarea>
                            </p>
                            <p class="comment-form-author">
                                <input id="author" placeholder="Name" type="text">
                            </p>
                            <p class="comment-form-email">
                                <input id="email" placeholder="Email"  type="text">
                            </p>
                            <p class="comment-form-url">
                                <input id="url" placeholder="Website" type="text">
                            </p>
                            <p class="form-submit">
                                <input name="submit" type="submit" id="submit" class="submit" value="Post Comment">
                            </p>
                        </form>
                    </div>
                </div>
            </div>
            <div class="col-lg-4 col-md-12">
                <div class="sidebar">
                    <div class="widget widget_search">
                        <form>
                            <input type="text" class="form-control" placeholder="Search here...">
                            <button type="submit"><i class="icofont-search"></i></button>
                        </form>
                    </div>
                    <div class="widget widget_categories">
                        <h3 class="widget-title">
                            Categories
                        </h3>
                        <ul>
                            <li><a href="#">AJAX</a></li>
                            <li><a href="#">Apache</a></li>
                            <li><a href="#">CSS</a></li>
                            <li><a href="#">PHP</a></li>
                            <li><a href="#">Django</a></li>
                            <li><a href="#">Error</a></li>
                            <li><a href="#">IIS</a></li>
                            <li><a href="#">JavaScript</a></li>
                        </ul>
                    </div>
                    <div class="widget widget_recent_entries">
                        <h3 class="widget-title">
                            Recent Posts
                        </h3>
                        <ul>
                            <li>
                                <a routerLink="/blog-details">
                                    <img src="assets/img/blog1.jpg" alt="image">
                                </a>
                                <h5><a routerLink="/blog-details">The most popular new top business apps</a></h5>
                                <p class="date">25 Feb, 2020</p>
                            </li>
                            <li>
                                <a routerLink="/blog-details">
                                    <img src="assets/img/blog2.jpg" alt="image">
                                </a>
                                <h5><a routerLink="/blog-details">The best marketing top use management tools</a></h5>
                                <p class="date">27 Feb, 2020</p>
                            </li>
                            <li>
                                <a routerLink="/blog-details">
                                    <img src="assets/img/blog3.jpg" alt="image">
                                </a>
                                <h5><a routerLink="/blog-details">3 wooCommerce plugins to boost sales</a></h5>
                                <p class="date">28 Feb, 2020</p>
                            </li>
                            <li>
                                <a routerLink="/blog-details">
                                    <img src="assets/img/blog4.jpg" alt="image">
                                </a>
                                <h5><a routerLink="/blog-details">Top 21 must-read blogs for creative agencies</a></h5>
                                <p class="date">29 Feb, 2020</p>
                            </li>
                        </ul>
                    </div>
                    <div class="widget widget_tag_cloud">
                        <h3 class="widget-title">
                            Tags
                        </h3>
                        <div class="tagcloud">
                            <a href="#">Error</a>
                            <a href="#">Cake Bake</a>
                            <a href="#">Dromzone</a>
                            <a href="#">File</a>
                            <a href="#">Yii</a>
                            <a href="#">Yii2</a>
                            <a href="#">UUID</a>
                            <a href="#">Setup</a>
                            <a href="#">Error</a>
                            <a href="#">Cake Bake</a>
                            <a href="#">Dromzone</a>
                            <a href="#">File</a>
                            <a href="#">Yii</a>
                            <a href="#">Yii2</a>
                            <a href="#">UUID</a>
                            <a href="#">Setup</a>
                        </div>
                    </div>
                    <div class="widget widget_archive">
                        <h3 class="widget-title">
                            Archives
                        </h3>
                        <ul>
                            <li><a href="#">December 2018</a></li>
                            <li><a href="#">January 2020</a></li>
                            <li><a href="#">February 2020</a></li>
                            <li><a href="#">March 2020</a></li>
                        </ul>
                    </div>
                </div>
            </div>
        </div>
    </div>
</section>