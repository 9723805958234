<div class="home-slides owl-carousel owl-theme">
    <div class="main-banner text-animation-home item-bg2">
        <div class="d-table">
            <div class="d-table-cell">
                <div class="container">
                    <div class="main-banner-content text-center">
                        <span>We Are Creative</span>
                        <h1>We Are 
                            <a href="" class="typewrite" data-period="2000" data-type='[ "Professional", "Passionate", "Creative", "Designer", "Unique", "Best", "Support" ]'>
                                <span class="wrap"></span>
                            </a>
                        </h1>
                        <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.</p>
                        <div class="btn-box">
                            <a href="#about" class="btn btn-primary">Get Started</a>
                            <span>or</span>
                            <a href="https://www.youtube.com/watch?v=bk7McNUjWgw" class="video-btn popup-youtube"><i class="icofont-ui-play"></i> Watch Video</a>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div class="shape1"><img src="assets/img/shapes/1.png" alt="shape1"></div>
        <div class="shape2 rotateme"><img src="assets/img/shapes/2.png" alt="shape2"></div>
        <div class="shape3 rotateme"><img src="assets/img/shapes/3.png" alt="shape3"></div>
        <div class="shape4"><img src="assets/img/shapes/4.png" alt="shape4"></div>
    </div>
    <div class="main-banner text-animation-home item-bg3">
        <div class="d-table">
            <div class="d-table-cell">
                <div class="container">
                    <div class="main-banner-content text-center">
                        <span>We Are Creative</span>
                        <h1>We Are 
                            <a href="" class="typewrite" data-period="2000" data-type='[ "Professional", "Passionate", "Creative", "Designer", "Unique", "Best", "Support" ]'>
                                <span class="wrap"></span>
                            </a>
                        </h1>
                        <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.</p>
                        <div class="btn-box">
                            <a href="#about" class="btn btn-primary">Get Started</a>
                            <span>or</span>
                            <a href="https://www.youtube.com/watch?v=bk7McNUjWgw" class="video-btn popup-youtube"><i class="icofont-ui-play"></i> Watch Video</a>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div class="shape1"><img src="assets/img/shapes/1.png" alt="shape1"></div>
        <div class="shape2 rotateme"><img src="assets/img/shapes/2.png" alt="shape2"></div>
        <div class="shape3 rotateme"><img src="assets/img/shapes/3.png" alt="shape3"></div>
        <div class="shape4"><img src="assets/img/shapes/4.png" alt="shape4"></div>
    </div>
    <div class="main-banner text-animation-home item-bg1">
        <div class="d-table">
            <div class="d-table-cell">
                <div class="container">
                    <div class="main-banner-content text-center">
                        <span>We Are Creative</span>
                        <h1>We Are 
                            <a href="" class="typewrite" data-period="2000" data-type='[ "Professional", "Passionate", "Creative", "Designer", "Unique", "Best", "Support" ]'>
                                <span class="wrap"></span>
                            </a>
                        </h1>
                        <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.</p>
                        <div class="btn-box">
                            <a href="#about" class="btn btn-primary">Get Started</a>
                            <span>or</span>
                            <a href="https://www.youtube.com/watch?v=bk7McNUjWgw" class="video-btn popup-youtube"><i class="icofont-ui-play"></i> Watch Video</a>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div class="shape1"><img src="assets/img/shapes/1.png" alt="shape1"></div>
        <div class="shape2 rotateme"><img src="assets/img/shapes/2.png" alt="shape2"></div>
        <div class="shape3 rotateme"><img src="assets/img/shapes/3.png" alt="shape3"></div>
        <div class="shape4"><img src="assets/img/shapes/4.png" alt="shape4"></div>
    </div>
</div>

<app-features></app-features>

<app-about></app-about>

<app-cta></app-cta>

<app-services></app-services>

<app-who-we-are></app-who-we-are>

<app-skills></app-skills>

<app-portfolio></app-portfolio>

<app-why-we-different></app-why-we-different>

<app-funfacts></app-funfacts>

<app-team></app-team>

<app-story></app-story>

<app-pricing></app-pricing>

<app-testimonial></app-testimonial>

<app-call-to-action></app-call-to-action>

<app-faq></app-faq>

<app-partner></app-partner>

<app-blog></app-blog>

<app-cta-two></app-cta-two>

<app-contact></app-contact>