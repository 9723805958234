<footer class="footer-area">
    <div class="container">
        <div class="row align-items-center">
            <div class="col-lg-6 col-md-7">
                <p><i class="icofont-copyright"></i>2020 ACR Medical</p>
            </div>

            <div class="col-lg-6 col-md-5">
                <ul>
                    <!-- <li><a href="#" target="_blank"><i class="icofont-facebook"></i></a></li>
                    <li><a href="#" target="_blank"><i class="icofont-twitter"></i></a></li>
                    <li><a href="#" target="_blank"><i class="icofont-instagram"></i></a></li> -->
                    <li><a href="https://www.linkedin.com/in/elemayne/" target="_blank"><i class="icofont-linkedin"></i></a></li>
                    <!-- <li><a href="#" target="_blank"><i class="icofont-pinterest"></i></a></li> -->
                </ul>
            </div>
        </div>
    </div>
</footer>

<div class="go-top"><i class="icofont-swoosh-up"></i></div>