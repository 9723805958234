import { Component, OnInit } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'app-navbar',
  templateUrl: './navbar.component.html',
  styleUrls: ['./navbar.component.scss']
})
export class NavbarComponent implements OnInit {

  public lang = 'en';
  constructor(private translate: TranslateService) { }

  ngOnInit() {
  }

  changeLanguage(lang): void {
    this.lang = lang;
    this.translate.setDefaultLang(lang);
  }
}
