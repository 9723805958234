<section class="who-we-are ptb-100 bg-image">
    <div class="container">
        <div class="row">
            <div class="col-lg-5 col-md-12">
                <div class="section-title">
                    <span>Who We Are</span>
                    <h2>We stand out of the <b>Avurn</b></h2>
                    <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.</p>
                    <a href="#portfolio" class="btn btn-primary">Our Work</a>
                </div>
            </div>
            <div class="col-lg-7 col-md-12">
                <div class="content">
                    <p>Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book. It has survived not only five centuries, but also the leap into electronic typesetting, remaining essentially unchanged.</p>
                    <ul>
                        <li>We Are Professional</li>
                        <li>We Are Passionate</li>
                        <li>We Are Creative</li>
                        <li>We Are Designer</li>
                        <li>We Are Unique</li>
                        <li>We Are Support</li>
                    </ul>
                </div>
            </div>
        </div>
    </div>
</section>