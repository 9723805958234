<section id="services" class="services-area ptb-100">
    <div class="container">
        <div class="section-title">
            <!-- <span>What We Do</span>
            <h2>Our <b>Services</b></h2>
            <p>We offer reliable and discreet advice to companies, in order to guarantee a complete service.</p> -->

            <span>{{'services.subTitle' | translate}}</span>
            <h2 [innerHTML]="'services.title' | translate"></h2>
            <p>{{'services.titleDesc' | translate}}</p>
                  
        </div>
        <div class="row">
            <div class="col-lg-4 col-md-6">
                <div class="single-services">
                    <div class="services-image">
                        <img src="assets/img/audit.jpg" alt="audit">
                        <div class="icon">
                            <i class="icofont-headphone-alt-3"></i>
                        </div>
                    </div>
                    <div class="services-content">
                        <!-- <h3>Audit</h3>
                        <ul>
                            <li>Mock audit (ISO 13485, CE Certificate)</li>
                            <li>Internal audit</li>
                        </ul> -->
                        <!-- <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit. Nam finibus velit nec.</p> -->
                        <!-- <h3>{{'services.auditTitle' | translate}}</h3>
                        <ul>
                            <li>{{'services.auditListItem1' | translate}}</li>
                            <li>{{'services.auditListItem2' | translate}}</li>
                        </ul> -->

                        <h3>{{'services.auditTitle' | translate}}</h3>
                        <ul>
                            <li>{{'services.auditListItem1' | translate}}</li>
                            <li>{{'services.auditListItem2' | translate}}</li>
                        </ul>
                    </div>
                </div>
            </div>
            <div class="col-lg-4 col-md-6">
                <div class="single-services">
                    <div class="services-image">
                        <img src="assets/img/training.png" alt="training">
                        <div class="icon">
                            <i class="icofont-anchor"></i>
                        </div>
                    </div>
                    <div class="services-content">
                    <!--     <h3>Training</h3>
                        <ul>
                            <li>Quality management system in compliance with ISO 13485:2016</li>
                            <li>Trainings on MDR 2017/745</li>
                            <li>Trainings proposed on site or online</li>
                        </ul> -->

                        <h3>{{'services.trainingTitle' | translate}}</h3>
                        <ul>
                            <li>{{'services.trainingListItem1' | translate}}</li>
                            <li>{{'services.trainingListItem2' | translate}}</li>
                            <li>{{'services.trainingListItem3' | translate}}</li>
                            <!-- <li>{{'services.trainingListItem4' | translate}} <a target="_blank" href="https://school.easymedicaldevice.com/courses-2">Easy Medical Device</a> {{'services.trainingListItem5' | translate}}</li> -->
                        </ul>
                        <!-- <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit. Nam finibus velit nec.</p> -->
                    </div>
                </div>
            </div>
            <div class="col-lg-4 col-md-6">
                <div class="single-services">
                    <div class="services-image">
                        <img src="assets/img/consulting.jpg" alt="image">
                        <div class="icon">
                            <i class="icofont-puzzle"></i>
                        </div>
                    </div>
                    <div class="services-content">

                        <!-- <h3>Consulting</h3>
                        <ul>
                            <li>Drafting documents on behalf of manufacturers</li>
                            <li>Proposition of templates</li>
                            <li>Follow-up, Regulatory watch</li>
                        </ul> -->

                        <h3>{{'services.consultingTitle' | translate}}</h3>
                        <ul>
                            <li>{{'services.consultingListItem1' | translate}}</li>
                            <li>{{'services.consultingListItem2' | translate}}</li>
                            <li>{{'services.consultingListItem3' | translate}}</li>
                        </ul>
                      
                        <!-- <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit. Nam finibus velit nec.</p> -->
                    </div>
                </div>
            </div>
            <!-- <div class="col-lg-4 col-md-6">
                <div class="single-services">
                    <div class="services-image">
                        <img src="assets/img/services-img4.jpg" alt="image">
                        <div class="icon">
                            <i class="icofont-tools"></i>
                        </div>
                    </div>
                    <div class="services-content">
                        <h3>App Development</h3>
                        <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit. Nam finibus velit nec.</p>
                    </div>
                </div>
            </div>
            <div class="col-lg-4 col-md-6">
                <div class="single-services">
                    <div class="services-image">
                        <img src="assets/img/services-img5.jpg" alt="image">
                        <div class="icon">
                            <i class="icofont-settings-alt"></i>
                        </div>
                    </div>
                    <div class="services-content">
                        <h3>Easy Customization</h3>
                        <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit. Nam finibus velit nec.</p>
                    </div>
                </div>
            </div>
            <div class="col-lg-4 col-md-6">
                <div class="single-services">
                    <div class="services-image">
                        <img src="assets/img/services-img6.jpg" alt="image">
                        <div class="icon">
                            <i class="icofont-puzzle"></i>
                        </div>
                    </div>
                    <div class="services-content">
                        <h3>Fully Responsive</h3>
                        <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit. Nam finibus velit nec.</p>
                    </div>
                </div>
            </div> -->
        </div>
    </div>
</section>