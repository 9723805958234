import { Component, OnInit } from '@angular/core';
import { MessageService } from 'src/app/services/message.service';
import { Validators, FormGroup, FormBuilder } from '@angular/forms';

@Component({
  selector: 'app-contact',
  templateUrl: './contact.component.html',
  styleUrls: ['./contact.component.scss']
})
export class ContactComponent implements OnInit {

  // tslint:disable-next-line:variable-name
  public formGroup1: FormGroup;

  constructor(
    private formBuilder: FormBuilder,
    private messageService: MessageService
  ) { }

  ngOnInit(): void {
    this.formGroup1 = this.formBuilder.group({
      name: ['', Validators.required],
      email: ['', Validators.required],
      subject: ['', Validators.required],
      phoneNumber: ['', Validators.required],
      message: ['', Validators.required]
    });
  }

  send(): void {
    const message = {
      name: this.formGroup1.value.name,
      email: this.formGroup1.value.email,
      subject: this.formGroup1.value.subject,
      phoneNumber: this.formGroup1.value.phoneNumber,
      message: this.formGroup1.value.message,
    };
    console.log(message);
    this.messageService.postMessage(message)
      .subscribe(
        x => {
          console.log(x);
        }
      )
      ;
  }

}
