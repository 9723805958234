<section id="contact" class="contact-area ptb-100 bg-image">
    <div class="container">
        <div class="section-title">
            <span>{{'contact.subTitle' | translate}}</span>
            <h2 [innerHTML]="'contact.title' | translate"></h2>
            <p>{{'contact.titleDesc' | translate}}</p>
         
        </div>
        <div class="row">
            <div class="col-lg-5 col-md-12">
                <div class="contact-info">
                    <div class="d-table">
                        <div class="d-table-cell">
                            <ul>
                                <!-- <li><i class="icofont-google-map"></i> <span>{{'contact.headOffice' | translate}}:</span>ACR MEDICAL 35 rue du Pitegny <br>
                                    01170 GEX / FRANCE</li> -->
                                <li><i class="icofont-envelope"></i> <span>{{'contact.email' | translate}}</span><a href="mailto:contact@acr-medical.com">contact@acr-medical.com</a></li>
                                <!-- <li><i class="icofont-phone"></i> <span>Call:</span><a href="tel:+1244122445515">(+124) 412-2445515</a></li>
                                <li><i class="icofont-fax"></i> <span>Fax:</span><a href="tel:+61724160055">+617-241-60055</a></li> -->
                                <li><i class="icofont-linkedin"></i> <span>Linkedin:</span><a href="https://www.linkedin.com/in/elemayne/" target="_blank">linkedin.com/in/elemayne</a></li>


                                
                            </ul>
                        </div>
                    </div>
                </div>
            </div>
            <div class="col-lg-7 col-md-12">
                <div class="contact-form">
                    <form [formGroup]="formGroup1">
                        <div class="row">
                            <div class="col-lg-6 col-md-6">
                                <div class="form-group">
                                    <input formControlName="name" type="text" class="form-control" placeholder="{{'contact.name' | translate}}">
                                </div>
                            </div>
                            <div class="col-lg-6 col-md-6">
                                <div class="form-group">
                                    <input formControlName="email"  type="email" class="form-control" placeholder="{{'contact.emailAddress' | translate}}">
                                </div>
                            </div>
                            <div class="col-lg-12 col-md-12">
                                <div class="form-group">
                                    <input formControlName="subject"  type="text" class="form-control" placeholder="{{'contact.subject' | translate}}">
                                </div>
                            </div>
                            <div class="col-lg-12 col-md-12">
                                <div class="form-group">
                                    <input formControlName="phoneNumber"  type="text" class="form-control" placeholder="{{'contact.phoneNumber' | translate}}">
                                </div>
                            </div>
                            <div class="col-lg-12 col-md-12">
                                <div class="form-group">
                                    <textarea formControlName="message"  placeholder="{{'contact.message' | translate}}" class="form-control" cols="30" rows="5"></textarea>
                                </div>
                            </div>
                            <div class="col-lg-12 col-md-12">
                                <button type="submit" class="btn btn-primary" (click)="send()">{{'contact.sendMessage' | translate}}</button>
                            </div>
                        </div>
                    </form>
                </div>
            </div>
        </div>
    </div>
</section>