<nav class="navbar navbar-expand-lg navbar-light bg-light">
    <div class="container">
        <a class="navbar-brand" routerLink="/">
            <img src="assets/img/logo.png" alt="logo">
            <img src="assets/img/logo2.png" alt="logo">
        </a>
        
        <button class="navbar-toggler" type="button" data-toggle="collapse" data-target="#navbarSupportedContent" aria-controls="navbarSupportedContent" aria-expanded="false" aria-label="Toggle navigation">
            <span class="navbar-toggler-icon"></span>
        </button>

        <div class="collapse navbar-collapse" id="navbarSupportedContent">
            <ul class="navbar-nav ml-auto">
                <li class="nav-item"><a class="nav-link" href="#home">{{'nav.home' | translate}}</a></li>
                <!-- <li class="nav-item"><a class="nav-link" href="#features">{{'nav.features' | translate}}</a></li> -->
                <li class="nav-item"><a class="nav-link" href="#features">{{'nav.activities' | translate}}</a></li>
                <li class="nav-item"><a class="nav-link" href="#about">{{'nav.about' | translate}}</a></li>
                <li class="nav-item"><a class="nav-link" href="#services">{{'nav.services' | translate}}</a></li>
                <!-- <li class="nav-item"><a class="nav-link" href="#portfolio">Portfolio</a></li>
                <li class="nav-item"><a class="nav-link" href="#team">Team</a></li>
                <li class="nav-item"><a class="nav-link" href="#pricing">Pricing</a></li>
                <li class="nav-item"><a class="nav-link" href="#testimonial">Testimonial</a></li>
                <li class="nav-item"><a class="nav-link" href="#blog">Blog</a></li> -->
                
                <li class="nav-item"><a class="nav-link" href="#contact">{{'nav.contact' | translate}}</a></li>
                <li *ngIf="lang !== 'en'"><img src="assets/img/flags/en.png" (click)="changeLanguage('en')" width="28px" height="18px"   alt="English" /></li>
                <li *ngIf="lang !== 'fr'"><img src="assets/img/flags/fr.png" (click)="changeLanguage('fr')" width="28px" height="18px"  alt="Français" /></li>
                </ul>
        </div>
    </div>
</nav>