<section id="blog" class="blog-area ptb-100">
    <div class="container">
        <div class="section-title">
            <span>Info Update!</span>
            <h2>Our Latest <b>News</b></h2>
            <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.</p>
        </div>
        <div class="blog-slides owl-carousel owl-theme">
            <div class="single-blog-post">
                <div class="blog-image">
                    <a routerLink="/blog-details"><img src="assets/img/blog1.jpg" alt="image"></a>
                    <div class="post-tag">
                        <a routerLink="#">Technology</a>
                    </div>
                </div>
                <div class="blog-post-content">
                    <span class="date">25 Feb, 2020</span>
                    <h3><a routerLink="/blog-details">The most popular new top business apps</a></h3>
                    <p>Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum printing typesetting.</p>
                    <a routerLink="/blog-details" class="read-more-btn">Read More <i class="icofont-double-right"></i></a>
                </div>
            </div>
            <div class="single-blog-post">
                <div class="blog-image">
                    <a routerLink="/blog-details"><img src="assets/img/blog2.jpg" alt="image"></a>
                    <div class="post-tag">
                        <a routerLink="#">Agency</a>
                    </div>
                </div>
                <div class="blog-post-content">
                    <span class="date">27 Feb, 2020</span>
                    <h3><a routerLink="/blog-details">The best marketing top use management tools</a></h3>
                    <p>Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum printing typesetting.</p>
                    <a routerLink="/blog-details" class="read-more-btn">Read More <i class="icofont-double-right"></i></a>
                </div>
            </div>
            <div class="single-blog-post">
                <div class="blog-image">
                    <a routerLink="/blog-details"><img src="assets/img/blog3.jpg" alt="image"></a>
                    <div class="post-tag">
                        <a routerLink="#">IT</a>
                    </div>
                </div>
                <div class="blog-post-content">
                    <span class="date">28 Feb, 2020</span>
                    <h3><a routerLink="/blog-details">3 wooCommerce plugins to boost sales</a></h3>
                    <p>Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum printing typesetting.</p>
                    <a routerLink="/blog-details" class="read-more-btn">Read More <i class="icofont-double-right"></i></a>
                </div>
            </div>
            <div class="single-blog-post">
                <div class="blog-image">
                    <a routerLink="/blog-details"><img src="assets/img/blog4.jpg" alt="image"></a>
                    <div class="post-tag">
                        <a routerLink="#">Creative</a>
                    </div>
                </div>
                <div class="blog-post-content">
                    <span class="date">29 Feb, 2020</span>
                    <h3><a routerLink="/blog-details">Top 21 must-read blogs for creative agencies</a></h3>
                    <p>Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum printing typesetting.</p>
                    <a routerLink="/blog-details" class="read-more-btn">Read More <i class="icofont-double-right"></i></a>
                </div>
            </div>
        </div>
    </div>
</section>