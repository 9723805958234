<section class="call-to-action ptb-100 jarallax" data-jarallax='{"speed": 0.3}'>
    <div class="container">
        <div class="call-to-action-content">
            <div class="section-title">
                <span>We are Best Company</span>
                <h2>Ready To <b>Work</b> With Us</h2>
                <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.</p>
                <a href="#contact" class="btn btn-primary">Contact Us</a>
            </div>
        </div>
    </div>
</section>