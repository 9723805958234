<div class="home-slides owl-carousel owl-theme">
    <div class="main-banner gradient-banner item-bg3">
        <div class="d-table">
            <div class="d-table-cell">
                <div class="container">
                    <div class="main-banner-content text-center">
                        <span>We Are Creative</span>
                        <h1>The Best <b>Digital</b> Experience</h1>
                        <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.</p>
                        <div class="btn-box">
                            <a href="#about" class="btn btn-primary">Get Started</a>
                            <span>or</span>
                            <a href="https://www.youtube.com/watch?v=bk7McNUjWgw" class="video-btn popup-youtube"><i class="icofont-ui-play"></i> Watch Video</a>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <div class="main-banner gradient-banner item-bg5">
        <div class="d-table">
            <div class="d-table-cell">
                <div class="container">
                    <div class="main-banner-content text-center">
                        <span>We Are Creative</span>
                        <h1>The Best <b>Digital</b> Experience</h1>
                        <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.</p>
                        <div class="btn-box">
                            <a href="#about" class="btn btn-primary">Get Started</a>
                            <span>or</span>
                            <a href="https://www.youtube.com/watch?v=bk7McNUjWgw" class="video-btn popup-youtube"><i class="icofont-ui-play"></i> Watch Video</a>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <div class="main-banner gradient-banner item-bg8">
        <div class="d-table">
            <div class="d-table-cell">
                <div class="container">
                    <div class="main-banner-content text-center">
                        <span>We Are Creative</span>
                        <h1>The Best <b>Digital</b> Experience</h1>
                        <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.</p>
                        <div class="btn-box">
                            <a href="#about" class="btn btn-primary">Get Started</a>
                            <span>or</span>
                            <a href="https://www.youtube.com/watch?v=bk7McNUjWgw" class="video-btn popup-youtube"><i class="icofont-ui-play"></i> Watch Video</a>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>

<app-features></app-features>

<app-about></app-about>

<app-cta></app-cta>

<app-services></app-services>

<app-who-we-are></app-who-we-are>

<app-skills></app-skills>

<app-portfolio></app-portfolio>

<app-why-we-different></app-why-we-different>

<app-funfacts></app-funfacts>

<app-team></app-team>

<app-story></app-story>

<app-pricing></app-pricing>

<app-testimonial></app-testimonial>

<app-call-to-action></app-call-to-action>

<app-faq></app-faq>

<app-partner></app-partner>

<app-blog></app-blog>

<app-cta-two></app-cta-two>

<app-contact></app-contact>