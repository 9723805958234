<section id="about" class="about-area ptb-100 bg-image">
    <div class="container">
        <div class="row align-items-center">
            <div class="col-lg-6 col-md-12">
                <div class="row about-image">
                    <div class="col-12">
                        <div class="image">
                            <img src="assets/img/elem.ayne.jpg" alt="Elem Ayne">
                        </div>
                    </div>
                    <!-- <div class="col-6 col-lg-6 col-md-6">
                        <div class="image">
                            <img src="assets/img/about-img2.jpg" alt="about">
                        </div>
                    </div>
                    <div class="col-6 col-lg-6 col-md-6">
                        <div class="image mt-30">
                            <img src="assets/img/about-img3.jpg" alt="about">
                        </div>
                    </div>
                    <div class="col-6 col-lg-6 col-md-6">
                        <div class="image mt-30">
                            <img src="assets/img/about-img4.jpg" alt="about">
                        </div>
                    </div> -->
                </div>
            </div>
            <div class="col-lg-6 col-md-12">
                <div class="about-content">
                    <!-- <span>About me</span>
                   -->
                    <!-- <h2>We Are Digital <b>Marketing</b> & Branding</h2> -->
                    <!-- <p>I am Elem Ayne, an independant consultant and a member of “DM Experts” network (in France). I have started my career in R&D in microbiology, oncology, and biochemistry.</p>
                    <p>I obtained 2 degrees in Business Development from 2 different Business Schools, then worked in several fields such as purchasing, supply chain, sales, marketing, quality and regulatory affairs for different industries, in particular in sterilization.</p>
                    <p>I have finally specialized myself in Regulatory affairs working especially in Class I reusable granted by a Master’s degree in this field. </p>
                    <p>I have founded “ACR Medical” company for only one objective : Support and help the maximum of manufacturers in their compliance with MDR (EU) 2017/745 to sell their medical devices in European market.</p>
                    <p>Totally committed, and thanks to a very skilled team in the medical devices sector, we have founded the first european association called "Team-PRRC" dedicated to the Persons Responsible for Regulatory Compliance (PRRC) as per article 15 of MDR (EU) 2017/745 & IVDR (EU) 2017/746. </p>
                    <p>Team-PRRC has been created to help PRRCs to more confidently assume this new profession which has considerable responsibilities, but also to establish, maintain and develop a high level of professionalism of PRRC.</p>
                -->
                    <span>{{'about.title' | translate}}</span>
                    <p>{{'about.titleDesc1' | translate}}</p>
                    <p>{{'about.titleDesc2' | translate}}</p>
                    <p>{{'about.titleDesc3' | translate}}</p>
                    <p>{{'about.titleDesc4' | translate}}</p>
                    <p>{{'about.titleDesc5' | translate}}</p>
                    <p>{{'about.titleDesc6' | translate}}</p>
                    
                  
                    <!-- <ul>
                        <li>Creative Design</li>
                        <li>Modern Design</li>
                        <li>Retina Ready</li>
                        <li>Awesome Design</li>
                        <li>Responsive Design</li>
                    </ul>
                    <p>We are an experienced and talented team of passionate consultants who live and breathe search engine marketing</p> -->
                </div>
                
            </div>
        </div>
    </div>
</section>