<section class="cta-area jarallax" data-jarallax='{"speed": 0.3}'>
    <div class="container">
        <div class="row align-items-center">
            <div class="col-lg-8 col-md-8">
                <div class="cta-content">
                    <h3>Create lovely & smart <b>websites</b> faster!</h3>
                    <span>Start to build your awesome websites</span>
                </div>
            </div>
            <div class="col-lg-4 col-md-4 text-right">
                <a href="#contact" class="btn btn-primary">Contact Us</a>
            </div>
        </div>
    </div>
</section>