<section id="features" class="features-area ptb-100 bg-image">
    <div class="container">
        <div class="section-title">
            <!-- <span>Our Features</span> -->
            <!-- <h2>We Always Try To <b>Understand</b> Users Expectation</h2>
            <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.</p>
        -->
        </div> 
        <div class="row">
            <div class="col-lg-6 col-md-6">
                <!-- <div class="services-image">
                    <img src="assets/img/services-img1.jpg" alt="image">
                    <div class="icon">
                        <i class="icofont-headphone-alt-3"></i>
                    </div>
                </div> -->
                <div class="single-features">
                    <img src="assets/img/regulatory-affairs.jpg" alt="image">
                    <div class="icon">
                        <i class="icofont-edit"></i>
                    </div>

                    <h3>{{'features.regulatoryTitle' | translate}}</h3>
                    <p>{{'features.regulatoryDesc1' | translate}}</p>
                    <p>{{'features.regulatoryDesc2' | translate}}</p>

                    <!-- <h3>Regulatory Affairs</h3>
                    <p>The Medical Device Regulation (MDR (EU) 2017/745) came into force in May 2017. Its date of application has been postponed to 26 May 2021 due to the global health crisis linked to COVID-19. This regulation repeals The Medical Device Directives 93/42/EEC and 90/385/EEC.</p>
                    <p>
                        ACR Medical is up-to-date with regulatory change and offers to stay in compliance via its services.
                    </p> -->
                </div>
            </div>
            <div class="col-lg-6 col-md-6">
                <div class="single-features active">
                    <img src="assets/img/quality-management.jpg" alt="image">
                    <div class="icon">
                        <i class="icofont-upload-alt"></i>
                    </div>
                    
                    <h3>{{'features.qualityTitle' | translate}}</h3>
                    <p>{{'features.qualityDesc1' | translate}}</p>
                    <p>{{'features.qualityDesc2' | translate}}</p>
                    <p>{{'features.qualityDesc3' | translate}}</p>

                    <!-- <h3>Quality Management</h3>
                    <p>ACR Medical supports the manufacturers in setting up quality management systems according to ISO 13485:2016. </p>
                    <p>
                        The Compliance with regulatory requirements requires optimal management of the quality management system.
                    </p>
                    <p>
                        Check out our services to find out more.
                    </p> -->
                </div>
            </div>
            <!-- <div class="col-lg-4 col-md-6 offset-lg-0 offset-md-3">
                <div class="single-features">
                    <div class="icon">
                        <i class="icofont-pie-chart"></i>
                    </div>
                    <h3>Business Growth</h3>
                    <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit. Nam finibus velit nec.</p>
                </div>
            </div> -->
        </div>
    </div>
</section>